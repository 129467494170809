import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PhotoIcon, SwatchIcon, ClockIcon, WrenchIcon } from '@heroicons/react/24/outline';
import { cn } from '../lib/utils';
import { Button } from './ui/button';

function Sidebar() {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const NavButton = ({ to, icon: Icon, children }) => (
    <Button
      variant={isActive(to) ? "secondary" : "ghost"}
      size="sm"
      className={cn(
        "w-full flex flex-col items-center gap-1 h-auto py-2",
        isActive(to) ? "bg-secondary" : "hover:bg-accent"
      )}
      asChild
    >
      <Link to={to}>
        <Icon className="h-5 w-5" />
        <span className="text-[10px]">{children}</span>
      </Link>
    </Button>
  );

  return (
    <div className="w-16 flex flex-col h-screen bg-background border-r">
      <div className="flex items-center justify-center flex-shrink-0 px-4 py-5">
        <img className="h-8 w-auto" src="/logo.png" alt="Logo" />
      </div>
      
      <nav className="flex-1 px-2 space-y-2">
        <NavButton to="/" icon={PhotoIcon}>
          Designs
        </NavButton>
        <NavButton to="/brands" icon={SwatchIcon}>
          Brands
        </NavButton>
        <NavButton to="/history" icon={ClockIcon}>
          History
        </NavButton>
      </nav>

      <div className="p-2">
        <NavButton to="/settings" icon={WrenchIcon}>
          Settings
        </NavButton>
      </div>
    </div>
  );
}

export default Sidebar;