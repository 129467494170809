import axios from 'axios';

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

authAxios.interceptors.request.use(
  config => {
    // Remove any leading slash to prevent double slashes
    const url = config.url.startsWith('/') ? config.url.substring(1) : config.url;
    config.url = url;
    
    // Log the full URL being requested (helpful for debugging)
    console.log('Making request to:', `${config.baseURL}/${url}`);
    
    const token = localStorage.getItem('jwt_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

authAxios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Response error:', error.response.data);
      console.error('Status:', error.response.status);
      
      if (error.response.status === 401) {
        localStorage.removeItem('jwt_token');
        window.location.reload();
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up request:', error.message);
    }
    return Promise.reject(error);
  }
);

export default authAxios;
