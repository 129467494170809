import React, { createContext, useState, useEffect, useContext } from 'react';
import { login as apiLogin, getUserUsageStats, getUserPlanDetails, getCurrentUser } from '../api/wordpress';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [userUsage, setUserUsage] = useState(null);
  const [userPlan, setUserPlan] = useState(null);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      try {
        const userData = await getCurrentUser();
        setUser(userData);
        setIsLoggedIn(true);
        await fetchUserUsageAndPlan();
      } catch (error) {
        console.error('Error checking login status:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
        }
        logout();
      }
    } else {
      logout();
    }
    setIsLoading(false);
  };

  const fetchUserUsageAndPlan = async () => {
    try {
      const [usageStats, planDetails] = await Promise.all([
        getUserUsageStats(),
        getUserPlanDetails()
      ]);
      setUserUsage(usageStats);
      setUserPlan(planDetails);
    } catch (error) {
      console.error('Error fetching user usage and plan:', error);
    }
  };

  const login = async (username, password) => {
    try {
      const response = await apiLogin(username, password);
      setIsLoggedIn(true);
      await checkLoginStatus(); // Fetch user data after successful login
      return true;
    } catch (error) {
      console.error('Login error:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      }
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('refresh_token');
    setIsLoggedIn(false);
    setUser(null);
    setUserUsage(null);
    setUserPlan(null);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, isLoading, user, userUsage, userPlan, login, logout, checkLoginStatus, fetchUserUsageAndPlan }}>
      {children}
    </AuthContext.Provider>
  );
};
